var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"ticketCorrectiveActionForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.ticketCorrectiveActionForm.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{staticClass:"white-background client-service-forms service-report-form",class:{ 'pointer-events-none': _vm.serviceForm.is_filled },attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-4"},[_c('v-col',{staticClass:"mb-3",staticStyle:{"border":"1px solid darkgrey"},attrs:{"md":"12"}},[_c('v-row',[_c('v-col',[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2 d-flex"},[_vm._v("Select one of following ")]),_c('v-radio-group',{staticClass:"ml-3 my-3",class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"mandatory":"","row":"","hide-details":"","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.selectradio),callback:function ($$v) {_vm.$set(_vm.attributes, "selectradio", $$v)},expression:"attributes.selectradio"}},[_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Service Call","value":"service-call","color":"cyan"}}),_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Inspection","value":"inspection","color":"cyan"}}),_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Installation","value":"installation","color":"cyan"}}),_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Follow-up","value":"follw-up","color":"cyan"}}),_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Delivery","value":"delivery","color":"cyan"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2 d-flex text-no-wrap"},[_vm._v("Maintenance Contract")]),_c('v-radio-group',{staticClass:"ml-3",class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"mandatory":"","row":"","hide-details":"","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.maintenancecontract),callback:function ($$v) {_vm.$set(_vm.attributes, "maintenancecontract", $$v)},expression:"attributes.maintenancecontract"}},[_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Yes","value":"yes","color":"cyan"}}),_c('v-radio',{staticClass:"mr-10",attrs:{"label":"No","value":"no","color":"cyan"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2"},[_vm._v("Request/Fault Reported")]),_c('v-textarea',{class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Request/Fault Reported","solo":"","flat":"","row-height":"25","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.request_fault_reported),callback:function ($$v) {_vm.$set(_vm.attributes, "request_fault_reported", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributes.request_fault_reported"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2"},[_vm._v("Diagnose/Fault Found")]),_c('v-textarea',{class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Diagnose/Fault Found","solo":"","flat":"","row-height":"25","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.diagnose_fault_found),callback:function ($$v) {_vm.$set(_vm.attributes, "diagnose_fault_found", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributes.diagnose_fault_found"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2"},[_vm._v("Job Done / Rectified")]),_c('v-textarea',{class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Job Done / Rectified","solo":"","flat":"","row-height":"25","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.job_done_rectified),callback:function ($$v) {_vm.$set(_vm.attributes, "job_done_rectified", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributes.job_done_rectified"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2"},[_vm._v("Remark/request")]),_c('v-textarea',{class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"auto-grow":"","dense":"","filled":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","label":"Remark/request","solo":"","flat":"","row-height":"25","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.remark_request),callback:function ($$v) {_vm.$set(_vm.attributes, "remark_request", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"attributes.remark_request"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"font-weight-700 font-size-18 pl-2 d-flex text-no-wrap"},[_vm._v("Service ticket status")]),_c('v-radio-group',{staticClass:"ml-3",class:{
                'pointer-events-none':
                  _vm.serviceForm.is_filled || _vm.is_staff || !_vm.engineerloginform,
              },attrs:{"mandatory":"","row":"","hide-details":"","readonly":!_vm.checkouttimedata},model:{value:(_vm.attributes.service_ticket_status),callback:function ($$v) {_vm.$set(_vm.attributes, "service_ticket_status", $$v)},expression:"attributes.service_ticket_status"}},[_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Completed","value":"completed","color":"cyan"}}),_c('v-radio',{staticClass:"mr-10",attrs:{"label":"Pending","value":"pending","color":"cyan"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }