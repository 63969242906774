<template>
  <v-container
    fluid
    class="white-background client-service-forms service-report-form"
    :class="{ 'pointer-events-none': serviceForm.is_filled }"
  >
    <v-row>
      <v-col md="12" class="pt-0">
        <div class="d-none">
          <v-text-field
            type="hidden"
            v-model="pageLoading"
            :rules="[validateRules.required(!pageLoading)]"
          ></v-text-field>
        </div>
        <label class="font-weight-700 font-size-16">
          <v-text-field
            dense
            filled
            color="cyan"
            :label="attributes.checklist.label"
            v-model="attributes.checklist.label"
            solo
            flat
            :disabled="pageLoading"
            :loading="pageLoading"
            hide-details
            class="mr-2 hide-border"
          >
          </v-text-field>
        </label>
        <v-row dense>
          <v-col
            md="6"
            v-for="(row, key) in attributes.checklist.checkbox"
            :key="key"
          >
            <v-text-field
              dense
              filled
              color="cyan"
              :label="row.label"
              v-model="row.label"
              :disabled="pageLoading"
              :loading="pageLoading"
              solo
              flat
              hide-details
            >
              <template v-slot:prepend>
                <v-checkbox
                  :disabled="pageLoading"
                  :value="1"
                  color="cyan"
                  class="hide-margin small"
                  hide-details
                  dense
                  v-model="row.value"
                ></v-checkbox>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="12">
        <label class="font-weight-700 font-size-16">
          <v-text-field
            dense
            filled
            color="cyan"
            :label="attributes.textarea_one.label"
            v-model="attributes.textarea_one.label"
            :disabled="pageLoading"
            :loading="pageLoading"
            solo
            flat
            hide-details
            class="mr-2 hide-border"
          >
          </v-text-field>
        </label>
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          :label="attributes.textarea_one.label"
          v-model="attributes.textarea_one.value"
          :disabled="pageLoading"
          :loading="pageLoading"
          solo
          flat
          hide-details
          row-height="25"
        ></v-textarea>
      </v-col>
      <v-col md="12">
        <label class="font-weight-700 font-size-16">
          <v-text-field
            dense
            filled
            color="cyan"
            :label="attributes.textarea_two.label"
            v-model="attributes.textarea_two.label"
            :disabled="pageLoading"
            :loading="pageLoading"
            solo
            flat
            hide-details
            class="mr-2 hide-border"
          >
          </v-text-field>
        </label>
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          :label="attributes.textarea_two.label"
          v-model="attributes.textarea_two.value"
          :disabled="pageLoading"
          :loading="pageLoading"
          solo
          flat
          hide-details
          row-height="25"
        ></v-textarea>
      </v-col>
      <v-col md="12">
        <v-layout>
          <v-flex class="mr-2">
            <label class="font-weight-700 font-size-16">
              <v-text-field
                dense
                filled
                color="cyan"
                :label="attributes.dates.started_at.label"
                v-model="attributes.dates.started_at.label"
                :disabled="pageLoading"
                :loading="pageLoading"
                solo
                flat
                hide-details
                class="mr-2 hide-border"
              >
              </v-text-field>
            </label>
            <DateTimePicker
              :disabled="pageLoading"
              :loading="pageLoading"
              :label="attributes.dates.started_at.label"
              v-model="attributes.dates.started_at.value"
            ></DateTimePicker>
          </v-flex>
          <v-flex class="ml-2">
            <label class="font-weight-700 font-size-16">
              <v-text-field
                dense
                filled
                color="cyan"
                :label="attributes.dates.finished_at.label"
                v-model="attributes.dates.finished_at.label"
                :disabled="pageLoading"
                :loading="pageLoading"
                solo
                flat
                hide-details
                class="mr-2 hide-border"
              >
              </v-text-field>
            </label>
            <DateTimePicker
              :disabled="pageLoading"
              :loading="pageLoading"
              :label="attributes.dates.finished_at.label"
              v-model="attributes.dates.finished_at.value"
            ></DateTimePicker>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="12">
        <label class="font-weight-700 font-size-16">
          <v-text-field
            dense
            filled
            color="cyan"
            :label="attributes.terms_conditions.label"
            v-model="attributes.terms_conditions.label"
            :disabled="pageLoading"
            :loading="pageLoading"
            solo
            flat
            hide-details
            class="mr-2 hide-border"
          >
          </v-text-field>
        </label>
        <v-textarea
          auto-grow
          dense
          filled
          color="cyan"
          :label="attributes.terms_conditions.label"
          v-model="attributes.terms_conditions.value"
          :disabled="pageLoading"
          :loading="pageLoading"
          solo
          flat
          hide-details
          row-height="25"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimePicker from "@/view/pages/partials/DateTimePicker";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  name: "work-order",
  mixins: [ValidationMixin],
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      pageLoading: true,
      attributes: {
        checklist: {
          label: "Check List",
          checkbox: [
            {
              label: "Clean Motor & Controller Compartment",
              value: null,
            },
            {
              label: "Clean Frame & Console Panel",
              value: null,
            },
            {
              label: "Check & Test Console For Abnormality",
              value: null,
            },
            {
              label: "Clean & Lubricate Running Deck",
              value: null,
            },
            {
              label: "Check Running Belt Alignment & Adjust Accordingly",
              value: null,
            },
            {
              label: "Check Running Belt Tightness & Adjust if Necessary",
              value: null,
            },
          ],
        },
        textarea_one: {
          label: "Service Rendered",
          value: null,
        },
        textarea_two: {
          label: "Engineer Remarks",
          value: null,
        },
        dates: {
          started_at: {
            label: "Started At",
            value: null,
          },
          finished_at: {
            label: "Finished At",
            value: null,
          },
        },
        terms_conditions: {
          label: "Terms & Conditions",
          value: null,
        },
      },
    };
  },
  components: {
    DateTimePicker,
  },
  methods: {
    updatePreInformation() {
      const _this = this;
      try {
        if (_this.lodash.isEmpty(_this.serviceForm) === false) {
          if (_this.lodash.isEmpty(_this.serviceForm.attributes) === false) {
            _this.attributes = _this.serviceForm.attributes;
          }
        }
      } catch (error) {
        _this.logError(error);
      } finally {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: _this.attributes, action },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
  },
  mounted() {
    const _this = this;

    ServiceFormEventBus.$on("start:save-service-form", (param) => {
      _this.saveServiceForm(param);
    });

    _this.updatePreInformation();
  },
};
</script>
